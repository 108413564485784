import DatePickerDate from '@/models/DatePickerDate'
import ReportBuilder from '@/models/ReportBuilder'
import moment from 'moment'

export default [
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    show: true,
    filter: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'type',
    label: 'Type',
    sortable: true,
    formatter: (v: string) => v.replaceAll('_', ' '),
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'frequency',
    label: 'Send Frequency',
    sortable: true,
    formatter: (v: string) => (v ? v.replaceAll('_', ' ') : '-'),
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'user_id',
    label: 'Owner',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'object',
    object: {
      key: 'owner',
      property: 'name',
    },
  },
  {
    key: 'shared',
    label: 'Shared',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'badge',
    color: (report_builder: ReportBuilder) => (report_builder.shared ? 'success' : 'danger'),
    formatter: (value: any, key: string, report_builder: ReportBuilder) =>
      (report_builder.shared ? 'Yes' : 'No'),
  },
  {
    key: 'expiration_picker',
    label: 'Expire At',
    sortable: true,
    formatter: (v: DatePickerDate) =>
      (v ? moment(v.end_date_string).format('MMMM, Do YYYY') : 'N/A'),
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'created_at',
    label: 'Created At',
    type: 'date',
    sortable: true,
    show: false,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'updated_at',
    label: 'Update At',
    sortable: true,
    class: 'text-center',
    type: 'date',
    show: true,
  },
  {
    key: 'action',
    label: 'Action',
    sortable: false,
    show: true,
    type: 'action_list',
    tdClass: 'actions',
    thStyle: { width: '160px' },
    actions: [
      // {
      //   icon: 'search',
      //   title: 'View Details',
      //   event: 'details',
      //   top_level: true,
      // },
      {
        icon: 'play',
        title: 'Run Report',
        event: 'run',
        top_level: true,
      },
      {
        icon: 'send',
        title: 'Send Report',
        event: 'send',
        top_level: true,
        show: (report: ReportBuilder) => report.to.length > 0,
      },
      {
        icon: 'pencil',
        title: 'Edit Report',
        event: 'edit',
      },
      {
        icon: 'copy',
        title: 'Clone Report',
        event: 'clone',
      },
      {
        icon: 'trash',
        title: 'Delete Report',
        event: 'delete',
      },
    ],
  },
]
