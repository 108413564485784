
import { Component, Ref } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import ReportBuilder from '@/models/ReportBuilder'
import DataTable from '@/components/DataTable/index.vue'
import WebMessage from '@/models/WebMessage'
import home_report_fields from './report-builder-fields'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
    DataTable,
  },
})
export default class ReportBuilderHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public report: ReportBuilder = new ReportBuilder()

  public sort_by: string = 'created_at'

  public sort_desc: boolean = true

  public page_size: number = 25

  public page: number = 1

  public records: number = 0

  public loading: boolean = false

  public query: string[] = []

  public fieldFilters: any = {}

  public modal: any = {
    delete: false,
    send: false,
  }

  public fields: object[] = home_report_fields

  public query_settings: any = {
    company_fields: [
      {
        name: 'company',
        key: 'company_id',
      },
      {
        name: 'station',
        key: 'station_id',
        type: 'station',
      },
    ],
    user_fields: [
      {
        name: 'owner',
        key: 'user_id',
      },
    ],
    custom_fields: [
      {
        name: 'period:none',
        value: 'period:none',
      },
      {
        name: 'period:daily',
        value: 'period:daily',
      },
      {
        name: 'period:weekly',
        value: 'period:weekly',
      },
      {
        name: 'period:monthly',
        value: 'period:monthly',
      },
      {
        name: 'period:yearly',
        value: 'period:yearly',
      },
      {
        name: 'station:n/a',
        value: 'station_id:',
      },
    ],
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public reports(context: any) {
    this.loading = true

    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    let query = [...context.filter, ...field_filters]

    return ReportBuilder.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query,
    }).then(response => {
      this.records = response.records
      this.loading = false
      return response.data
    })
  }

  public confirmDelete(report: ReportBuilder) {
    this.report = report
    this.modal.delete = true
  }

  public confirmSend(report: ReportBuilder) {
    this.report = report
    this.modal.send = true
  }

  public createReport() {
    this.$router.push('/app/report-builder')
  }

  public editReport(report: ReportBuilder) {
    this.$router.push(`/app/report-builder/${report.id}`)
  }

  public runReport(report: ReportBuilder) {
    this.$router.push(`/app/report-builder/${report.id}?run=1`)
  }

  public deleteReport() {
    this.report.delete().then(() => {
      this.modal.delete = false
      this.dataTable.refresh()
    })
  }

  public cloneReport(report: ReportBuilder) {
    this.$router.push(`/app/report-builder?from=${report.id}`)
  }

  public sendReport(report: ReportBuilder) {
    let emails = ''
    report.to.forEach((email: string) => {
      emails += `- <strong>${email}</strong><br />`
    })
    WebMessage.confirm(
      `Are you sure you want to send this report to all emails listed in the report? <br/>${emails}`,
      'Send report',
      {
        okTitle: 'Send',
      },
    ).then(response => {
      if (response) {
        report.send().then(() => {
          WebMessage.success('Report added to the queue, Email should go out soon.')
        })
      }
    })
  }
}
